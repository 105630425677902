.line-header {
    height: 10vh;    
}

.line-1 {
    height: 60vh;    
}

.line-2 {
    height: 30vh;
}

#logo {
    /* width: 250px; */
    height: 9vh;
}