.line-header-height {
    height: 10vh;
}

.line-1 {
    height: 60vh;
}

.line-2 {
    height: 30vh;
}

#logo {
    height: 9vh;
    width: auto;
    max-width: 100%;
}