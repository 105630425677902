td {
    /* background-color: red!important; */
    overflow-x: hidden;
    white-space: 'nowrap';
}


.row-col {
    white-space: nowrap; /* Evita que o texto quebre */
    overflow: hidden; /* Oculta o conteúdo que estourar a largura */
    text-overflow: ellipsis; /* Adiciona reticências (...) ao texto oculto */
}