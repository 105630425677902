body {
  background-color: #dfdfdf;
}

.theme-bg-color {
  background-color: #dfdfdf !important;
}

.theme-primary-font-color {
  color: #547931 !important;
}

.theme-colors-items {
  /* background-color: #dfdfdf !important; */
  color: #dfdfdf !important;
  background-color: #547931 !important;
}

th {
  background-color: transparent !important;
}

td {
  background-color: transparent !important;
}

.title {
  color: #ffffff;
  background-color: #547931;
  border-radius: 35px;
  border: 10px solid #547931;
}

.overflowy {
  overflow-y: auto;
  overflow-x: hidden;
}

.flash-yellow {
  border-radius: 35px;
  padding: 10px;
  background-color: yellow;
  animation: flash-yellow 1s linear infinite;
}


@keyframes flash-yellow {
  0% {
    /* opacity: 1; */
    background-color: transparent;
  }

  100% {
    background-color: yellow;
    /* opacity: 0; */
  }
}

.flash-red {
  border-radius: 35px;
  padding: 10px;
  animation: flash-red 1.5s ease-in-out infinite;
  background-color: #FF6666;
  color: #000000;
  overflow-y: auto!important;  
  overflow-x: hidden;
}

@keyframes flash-red {
  0% {
    /* opacity: 1; */
    background-color: transparent;
  }

  100% {
    background-color: #FF6666;
    /* opacity: 1; */
  }
}